<template>
  <div class="ProjectList">
    <el-tabs type="border-card">
      <el-tab-pane label="项目卡片展示">
        <el-input v-model="projectName">
          <template slot="prepend"><span>工程名称</span></template>
          <el-button slot="append" icon="el-icon-search" @click="findAllProject">查询</el-button>
        </el-input>
        <div class="cardList clearfix">
          <el-card :body-style="{ padding: '10px' }" shadow="hover" class="box-card" v-for="(item, index) in dataSource" :key="item.pkTaskId + 'card'" :class="{ lv4: item.safeState === '警报', marginRight0: index % 5 === 0 }">
            <div @click="goProject(item.pkTaskId)">
              <div class="item-noPic" :class="item.safeState === '警报' ? 'lv4' : ''" v-if="!(item.plan || item.flatPatternmakingOne || item.flatPatternmakingTwo)"></div>
              <!--外层用双引号或者内层用单引号就报错，大概只能这样子写...不懂eslint的规则...有毒-->
              <div
                class="item-pic"
                v-else
                :style="{
                  backgroundImage: ' url(&quot;' + (item.plan || item.flatPatternmakingOne || item.flatPatternmakingTwo) + '&quot;)',
                }"
              ></div>

              <div class="item-security" v-if="item.fkSafeStateId === 3" :style="safeState.bgLevel4"><i class="el-icon-error" />报警</div>
              <div class="item-security" v-else-if="item.fkSafeStateId === 2" :style="safeState.bgLevel3"><i class="el-icon-warning" />预警</div>
              <div class="item-security" v-else :style="safeState.bgLevel0"><i class="el-icon-success" />正常</div>
              <div class="item-status">状态: {{ item.runningState }}</div>

              <div class="title item">
                <h2 class="item">{{ item.projectName }}</h2>
              </div>
              <div class="text item">
                {{ '工程地址:' + item.projectAddress }}
              </div>
              <div class="text item">
                {{ '工程编号:' + item.projectNo }}
              </div>
              <div class="text item">
                {{ '所属主机:' + item.hostNumber }}
              </div>
              <div class="text item" v-if="$store.state.power === 4">
                {{ '所属公司:' + item.companyName }}
              </div>
              <div class="text item">
                {{ '监测单位:' + '广州翰南工程技术有限公司' }}
              </div>
              <div class="text item">
                {{ '监测编号:' + item.supervisionPlatformNo }}
              </div>
            </div>
          </el-card>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      </el-tab-pane>

      <el-tab-pane label="项目管理表格">
        <el-input v-model="projectName">
          <template slot="prepend"><span>工程名称</span></template>
          <el-button slot="append" icon="el-icon-search" @click="findAllProject">查询 </el-button>
        </el-input>
        <MyTable :dataSource="dataSource" :columns="columns" border stripe :loading="loading" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" @handleClick="handleClick"></MyTable>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { level0, level1, level2, level3, level4 } from '@/utils/color'
import { findAllTask, findAllTaskByCompanyUser, findAllTaskByHostUser, findAllTaskByUser } from '@/api/Task.js'

// const columns = []
const dataSource = []
export default {
  name: 'ProjectList',
  components: { MyTable },
  data() {
    return {
      // 表
      currentPage: 0,
      pageSize: 10,
      total: null,
      loading: true,
      dataSource,
      columns: [],
      projectName: null,
      safeState: {
        level0: 'color: ' + level0,
        level1: 'color: ' + level1,
        level2: 'color: ' + level2,
        level3: 'color: ' + level3,
        level4: 'color: ' + level4,
        bgLevel0: 'backgroundColor: ' + level0,
        bgLevel1: 'backgroundColor: ' + level1,
        bgLevel2: 'backgroundColor: ' + level2,
        bgLevel3: 'backgroundColor: ' + level3,
        bgLevel4: 'backgroundColor: ' + level4,
      },
    }
  },
  methods: {
    async findTaskByUser() {
      this.loading = true
      if (this.$store.state.power === 4) {
        return await findAllTask(this.projectName, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 3) {
        return await findAllTaskByCompanyUser(this.projectName, this.$store.state.unitId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 2) {
        return await findAllTaskByHostUser(this.projectName, this.$store.state.userId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 1) {
        return await findAllTaskByUser(this.projectName, this.$store.state.userId, this.currentPage, this.pageSize)
      }
    },
    async findAllProject() {
      this.loading = true
      const { data: res } = await this.findTaskByUser()
      console.log('任务数据', res.data.list)
      this.dataSource = res.data.list
      this.dataSource.forEach((item) => {
        item.hostNumber = item.host.hostNumber
        item.company ? (item.companyName = item.company.companyName) : ''
        item.state ? (item.runningState = item.state.runningState) : ''
        item.safeState ? (item.safeState = item.safeState.safeState) : ''
      })
      this.total = res.data.total
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    handleClick(row, type) {
      console.log(row, type)
      switch (type) {
        case 'del':
          console.log('del')
          break
        case 'edit':
          this.goProject(row.pkTaskId)
          break
      }
    },
    handleClose() {},
    handleSizeChange(val) {
      this.pageSize = val
      console.log('this.pageSize', this.pageSize)
      this.findAllProject()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log('this.currentPage', this.currentPage)
      this.findAllProject()
    },
    goProject(projectId) {
      window.open('/ProjectMonitor/' + (projectId - 0) + '/Condition')
    },
  },
  created() {
    this.findAllProject()
    if (this.$store.state.power === 4) {
      this.columns = [
        {
          prop: 'fkSafeStateId',
          label: '安全状态',
          width: 150,
          type: 'icon2',
          align: 'center',
        },

        {
          prop: 'projectNo',
          label: '工程编号',
          width: 120,
          type: 'text',
          align: 'center',
          sortable: true,
        },
        {
          prop: 'projectName',
          label: '工程名称',
          // width: 100,
          type: 'text',
          align: 'center',
        },
        {
          prop: 'projectAddress',
          label: '工程地址',
          type: 'text',
          align: 'center',
        },
        {
          prop: 'hostNumber',
          label: '所属主机',
          type: 'text',
          align: 'center',
        },
        {
          prop: 'companyName',
          label: '所属公司',
          type: 'text',
          align: 'center',
        },

        {
          prop: 'runningState',
          label: '运行状态',
          width: 150,
          type: 'text',
          align: 'center',
        },
        {
          prop: 'edit',
          label: '查看详情',
          align: 'center',
          type: 'button',
          width: 80,
          buttonInfo: {
            text: '详情',
            type: 'warning',
            size: 'mini',
          },
        },
      ]
    } else {
      this.columns = [
        {
          prop: 'fkSafeStateId',
          label: '安全状态',
          width: 150,
          type: 'icon2',
          align: 'center',
        },

        {
          prop: 'projectNo',
          label: '工程编号',
          width: 120,
          type: 'text',
          align: 'center',
          sortable: true,
        },
        {
          prop: 'projectName',
          label: '工程名称',
          // width: 100,
          type: 'text',
          align: 'center',
        },
        {
          prop: 'projectAddress',
          label: '工程地址',
          type: 'text',
          align: 'center',
        },
        {
          prop: 'hostNumber',
          label: '所属主机',
          type: 'text',
          align: 'center',
        },

        {
          prop: 'runningState',
          label: '运行状态',
          width: 150,
          type: 'text',
          align: 'center',
        },
        {
          prop: 'edit',
          label: '查看详情',
          align: 'center',
          type: 'button',
          width: 80,
          buttonInfo: {
            text: '详情',
            type: 'warning',
            size: 'mini',
          },
        },
      ]
    }
    console.log('数组长度', this.columns.length)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
@import '../../assets/css/color.scss';

.ProjectList {
  padding: 15px;
  // width: calc(100% - 30px);
  .el-input {
    margin-bottom: 20px;
    width: 600px;
  }
}
.cardList {
  // width: 1920px;

  .box-card {
    width: 350px;
    margin: 10px 15px 10px 0;
    // height: 350px;
    float: left;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    bottom: 0;
    box-shadow: 0px 0px 10px #b9b9b9;
    &:hover {
      bottom: 10px;
      box-shadow: 0px 6px 12px #d4d4d4;
    }
    .marginRight0 {
      margin-right: 0;
    }
    .item-pic {
      height: 320px;
      width: calc(100% - 2px);
      // border: 1px solid #d9d9d9;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background-size: cover;
      background-position: center;
    }
    .item-noPic {
      height: 320px;
      width: calc(100% - 2px);
      overflow: hidden;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background: url('~@/assets/image/noPhoto.png') no-repeat center center;
    }
    .item-security {
      position: absolute;
      width: 60px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
      font-family: '宋体';
      border-radius: 22px;
      top: 16px;
      right: 14px;
      font-weight: bold;
      color: #ffffff;
    }
    .item-status {
      position: absolute;
      width: 100px;
      height: 22px;
      top: 300px;
      left: 20px;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
      font-family: '宋体';
      border-radius: 22px;
      color: #ffffff;
      font-weight: bolder;
      background-color: rgba(58, 58, 58, 0.58);
    }

    .title {
      font-size: 24px;
      line-height: 36px;
      height: 36px;
    }
    .item {
      width: 330px;
      overflow: hidden;
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
    }
    .text {
      font-size: 14px;
      line-height: 24px;
      height: 24px;
    }
  }
  .lv4 {
    // background-color: $lv4;
    // background-color: rgba($color: $lv4, $alpha: 0.6);
    // color: #fff;
    // width: 346px;
    // border: 2px solid $lv4;
    // margin-bottom: 0px;
  }
}
</style>
